.title-text {
  margin: 0;
}

.anio {
  font-weight: normal;
  font-size: 0.5em;
  color: gray;
}

.App {
  position: relative;
  min-height: 100vh;
}

.title {
  text-align: center;
}

.content {
  padding-bottom: 70px; /* Alto del footer */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: black;
  color: white;
}

.footer-about {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-txt {
  text-align: center;
  color: rgb(240, 240, 240);
  margin: 1px;
}

.autor-link {
  color: #7ca9ec;
  text-decoration: none;
}

.autor-link:hover {
  color: white;
}

.ultimaActualizacion {
  color: rgb(100, 100, 100);
}

.githubLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1%;
  height: 40px;
  width: 40px;
  padding: 5px;
  fill: rgb(240, 240, 240);
}

.githubLogo:hover {
  fill: white;
}
